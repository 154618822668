<template>
  <div class="billingUpload">
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-1">{{ title }}</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <v-card :loading="loading" class="mb-4 pa-4">
        <v-container>
          <v-row>
            <v-col cols="12">
              <company-select
                @chosen="setCompany"
                v-model="companySelected"
              ></company-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <!-- insert credit form -->
      <v-card class="mb-4 pa-4" v-if="companySelected.id">
        <v-row>
          <v-col cols="12">
            <h1>Save Credit Record For {{ companySelected.name }}</h1>
          </v-col>
          <v-col cols="12">
            <label for="datePicker">Date for record</label>
          </v-col>
          <v-col cols="3">
            <input
              type="date"
              class="input"
              name="datePicker"
              id="datePicker"
              v-model="insertForm.date"
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              outlined
              dense
              label="Deposit"
              v-model="insertForm.deposit"
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              outlined
              dense
              label="Withdrawal"
              v-model="insertForm.withdrawal"
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              outlined
              dense
              label="Reference"
              v-model="insertForm.reference"
            />
          </v-col>
          <v-col cols="12" align="center">
            <v-btn
              color="primary"
              class="mb-2 ml-2"
              :disabled="loading"
              @click="insertCompanyBillingCredit()"
              >Save Record</v-btn
            >
          </v-col>
        </v-row>
      </v-card>

      <!-- credit rows -->
      <v-card class="mb-4 pa-4" v-if="companySelected.id">
        <v-row>
          <v-col cols="12">
            <h1>{{ title }} Records For {{ companySelected.name }}</h1>
          </v-col>
        </v-row>
        <v-row v-if="data.length > 0">
          <v-col cols="6">
            <ExportButtons :headers="headers" :data="data" :title="title" />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="data"
              :items-per-page="5"
              :search="search"
            >
            <template v-slot:item.deposit="{ item }">
              {{renderCurrency(item.deposit)}}
            </template>
            <template v-slot:item.withdrawal="{ item }">
              {{renderCurrency(item.withdrawal)}}
            </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="#B22222"
                  :disabled="loading"
                  @click="deleteEntry(item)"
                >
                  <v-icon dark>mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12"> No records found </v-col>
        </v-row>
      </v-card>

      <!-- current credit report -->
      <v-card
        class="mb-4 pa-4"
        v-if="companySelected.id && currentCreditReport"
      >
        <v-row>
          <v-col cols="9">
            <h1>Credit Balance Report For {{ companySelected.name }}</h1>
          </v-col>
          <v-col cols="3"  v-if="currentCreditReport.length > 0" justify="end" >
            <h2><b>Available Credit:</b> {{ renderCurrency(currentCreditReport[currentCreditReport.length - 1].current_balance) }}</h2>
          </v-col>
        </v-row>
        <v-row v-if="currentCreditReport.length > 0">
          <v-col cols="6" >
            <ExportButtons
              :headers="currentCreditReportHeaders"
              :data="currentCreditReport"
              :title="'Credit Balance Report' + companySelected.name"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-data-table
              :headers="currentCreditReportHeaders"
              :items="currentCreditReport"
              :items-per-page="10"
              :search="search"
            >
            <template v-slot:item.original_balance="{ item }">
              {{renderCurrency(item.original_balance)}}
            </template>
            <template v-slot:item.unbilled_work="{ item }">
              {{renderCurrency(item.unbilled_work)}}
            </template>
            <template v-slot:item.current_balance="{ item }">
              {{renderCurrency(item.current_balance)}}
            </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12"> No records found </v-col>
        </v-row>
      </v-card>
    </div>
    <v-snackbar v-model="snackbar" :timeout="5000">
      {{ text }}
      <v-btn color="blue" text @click="snackbar = false"></v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import BillingSnap from "@/services/BillingSnap.js";
import ExportButtons from "@/components/ExportButtons";
import CompanySelect from "../components/forms/CompanySelect";
export default {
  name: "CompanyBillingCredit",
  components: { ExportButtons, CompanySelect },
  data() {
    return {
      title: "Company Billing Credit",
      // Snackbar
      snackbar: false,
      text: "",
      response: null,

      search: "",
      loading: false,

      companySelected: {"id":"253","code":"FMW","name":"FM WORLD","primary_email":"fmwdistlist@3p-logistics.co.uk"},

      data: [],

      headers: [
        { text: "Company Id", value: "company_id" },
        { text: "Deposit", value: "deposit" },
        { text: "Withdrawal", value: "withdrawal" },
        { text: "Reference", value: "reference" },
        { text: "Date", value: "date" },
        { text: "", value: "actions" },
      ],

      insertForm: {
        company_id: "",
        deposit: "",
        withdrawal: "",
        reference: "",
        date: "",
      },
      currentCreditReport: null,
      currentCreditReportHeaders: [
        { text: "Charge Date", value: "charge_date" },
        { text: "Original Balance", value: "original_balance" },
        { text: "Unbilled Work", value: "unbilled_work" },
        { text: "Current Balance", value: "current_balance" },
      ],
    };
  },
  created() {
    this.getCompanyBillingCredit();
  },

  methods: {
     renderCurrency(value) {
      if (value === null) {
        value = 0.0;
      }
      return `£${parseFloat(value).toFixed(2)}`;
    },
    resetForm() {
      let todaysDate = new Date();
      this.insertForm = {
        company_id: this.companySelected.id,
        deposit: "",
        withdrawal: "",
        reference: "",
        date: todaysDate.toISOString().slice(0, 10),
      };
    },
    setCompany(company) {
      this.companySelected = company;
      this.resetForm();
      this.data = [];
      this.currentCreditReport = null;
      this.getCompanyBillingCredit();
    },
    async insertCompanyBillingCredit() {
      this.insertForm.company_id = this.companySelected.id
      this.loading = true;
      BillingSnap.insertCompanyBillingCredit(this.insertForm)
        .then(
          ((data) => {
            this.loading = false;
            this.snackbar = true;
            this.text = data;
            this.resetForm();
            setTimeout(async () => {
              this.getCompanyBillingCredit();
            }, 2000);
          }).bind(this)
        )
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.response.data.message}`;
          this.loading = false;
          return;
        });
    },
    async getCompanyBillingCredit() {
      this.loading = true;
      BillingSnap.getCompanyBillingCredit(this.companySelected.id)
        .then(
          ((data) => {
            this.$set(this, "data", data);
            this.loading = false;
            this.snackbar = true;
            this.text = `Successfully Loaded Data`;
            this.getCurrentCompanyBillingCredit();
          }).bind(this)
        )
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.response.data.message}`;
          this.loading = false;
          return;
        });
    },
    async getCurrentCompanyBillingCredit() {
      this.loading = true;
      BillingSnap.getCurrentCompanyBillingCredit(this.companySelected.id)
        .then(
          ((data) => {
            this.currentCreditReport = data;

            this.loading = false;
          }).bind(this)
        )
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.response.data.message}`;
          this.loading = false;
          return;
        });
    },

    deleteEntry(entry) {
      if (confirm("Confirm Deletion of Entry on date " + entry.date)) {
        BillingSnap.deleteCompanyBillingCredit({ id: entry.id })
          .then(() => {
            this.getCompanyBillingCredit();
            this.loading = false;
          })
          .catch((error) => {
            this.snackbar = true;
            this.text = `${error.response.data.message}`;
            this.loading = false;
            return;
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.description {
  margin-bottom: 30px;
}
.error {
  background-color: red;
}
h1{
  font-size: 120%;
  font-weight: bold;
}
</style>